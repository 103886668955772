import {
  fetchFlightDetailsFailed,
  fetchFlightDetailsSuccess,
  fetchFlightDetailsInvoked,
  FLIGHTDETAILS_FETCH_DATA,
} from "src/redux/actions/flightDetails";
import { put, call, takeEvery } from "redux-saga/effects";
import { fetchData } from "src/services/flightDetails";
import { shouldMockData } from "src/common";

async function getMockItineraries(properties) {
  const { mockItineraries } = await import("src/common/mocks/data/flights.js");
  return mockItineraries(properties, true);
}

function* fetchFlightDetails({ id, timeFrames }) {
  try {
    yield put(fetchFlightDetailsInvoked(id));
    const properties = { timeFrames, CompanyFeatureID: id };
    const payload = shouldMockData()
      ? yield getMockItineraries(properties)
      : yield call(fetchData, properties);
    yield put(fetchFlightDetailsSuccess(payload, id));
  } catch (ex) {
    yield put(fetchFlightDetailsFailed(ex, id));
  }
}
function* flightDetailsSaga() {
  yield takeEvery(FLIGHTDETAILS_FETCH_DATA, fetchFlightDetails);
}

export default flightDetailsSaga;
