import {
  fetchItineraryDetailsFailed,
  fetchItineraryDetailsSuccess,
  fetchItineraryDetailsInvoked,
  ITINERARYDETAILS_FETCH_DATA,
} from "src/redux/actions/itineraryDetails";
import { put, call, select, takeEvery } from "redux-saga/effects";
import {
  fetchData,
  getTravelerTrackerProperties,
} from "src/services/itineraryDetails";
import { getStatuses } from "src/redux/actions/travelerTracker";
import { shouldMockData } from "src/common";

function getWidgets(state) {
  return state.widgets;
}

async function getMockItineraries(properties) {
  const { mockItineraries } = await import("src/common/mocks/data/flights.js");
  return mockItineraries(properties);
}

function* fetchItineraryDetails({ timeFrames, id }) {
  try {
    yield put(fetchItineraryDetailsInvoked(id));
    const widgets = yield select(getWidgets);
    const properties = getTravelerTrackerProperties(widgets, id);
    if (timeFrames) properties.timeFrames = timeFrames;
    const payload = shouldMockData()
      ? yield getMockItineraries(properties)
      : yield call(fetchData, properties);
    yield put(fetchItineraryDetailsSuccess(payload, id));
    yield put(getStatuses(id));
  } catch (ex) {
    yield put(fetchItineraryDetailsFailed(ex, id));
  }
}

function* itinerariesSaga() {
  yield takeEvery(ITINERARYDETAILS_FETCH_DATA, fetchItineraryDetails);
}

export default itinerariesSaga;
