import { get } from "src/common/apiClient";
import { getConfig } from "src/apiConfig";
import { handleErrors, FLIGHTDETAILS } from "src/common";

export const fetchData = ({ CompanyFeatureID }) => {
  const url = getConfig(FLIGHTDETAILS).url;
  if (url) {
    return get({
      url,
      params: {
        CompanyFeatureID,
      },
    })
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${FLIGHTDETAILS} url`));
  }
};
