import * as constants from "src/common/constants";
/**
 * Base Url of API server.  Important to append ending slash.
 */
export const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const APPROVE_BASE_URL = process.env.REACT_APP_API_APPROVE_BASE_URL;

/**
 * Api configuration for each resource, add a types for auto generated action and reducer in redux
 */
export const config = {
  [constants.SELF_REGISTRATION]: {
    url: `${BASE_URL}SelfRegistration`,
  },
  [constants.TRAVEL_RESTRICTIONS]: {
    url: `${BASE_URL}TravelRestrictions`,
  },
  [constants.STATE]: {
    url: `${BASE_URL}state/application`,
  },
  [constants.WIDGETPROPERTIES]: {
    url: `${BASE_URL}Features/User`,
  },
  [constants.SSO]: {
    url: `${BASE_URL}features/sso/link`,
  },
  [constants.WIDGET]: {
    url: `${BASE_URL}widget`,
  },
  [constants.AGENT_CONTACT]: {
    url: `/contacts.json`,
  },
  [constants.AIRLINES]: {
    url: `${BASE_URL}airlines`,
  },
  [constants.AIRPORTS]: {
    url: `${BASE_URL}search/airports`,
  },
  [constants.APPROVE]: {
    url: `${APPROVE_BASE_URL}approvals`,
  },
  [constants.APPROVE_CSV]: {
    url: `${APPROVE_BASE_URL}reports/csv`,
  },
  [constants.APPROVE_DELEGATE]: {
    url: `${APPROVE_BASE_URL}DelegateApprover`,
  },
  [constants.USERS]: {
    url: `${APPROVE_BASE_URL}Users/Search`,
  },
  [constants.DECREMENT_ACTIVE_SESSION]: {
    url: `${AUTH_BASE_URL}DecrementActiveSession`,
  },
  [constants.FLIGHTDETAILS]: {
    url: `${BASE_URL}Flights/upcoming`,
  },
  [constants.IMPERSONATION]: {
    url: `${BASE_URL}Impersonation`,
  },
  [constants.INVOICES]: {
    url: `${BASE_URL}MyTripReceiptsWidget`,
  },
  [constants.ITINERARYDETAILS]: {
    url: `${BASE_URL}itineraries`,
  },
  [constants.FAREFORECASTER]: {
    url: `${BASE_URL}flights/lowfares`,
  },
  [constants.BUYRECOMMENDATION]: {
    url: `${BASE_URL}flights/recommendation`,
  },
  [constants.NEWS]: {
    url: `${BASE_URL}newsitems`,
  },
  [constants.BI]: {
    url: `${BASE_URL}expense`,
  },
  [constants.COMPANY_FEATURES]: {
    url: `${BASE_URL}CompanyFeatures`,
  },
  [constants.COUNTRIES]: {
    url: `${BASE_URL}locations/countries`,
  },
  [constants.MICROSITE_COUNTRIES]: {
    url: `${BASE_URL}Microsite/locations/countries`,
  },
  [constants.CURRENCIES]: {
    url: `${BASE_URL}currency/symbols`,
  },
  [constants.CURRENCIES_TIME_SERIES]: {
    url: `${BASE_URL}currency/history`,
  },
  [constants.COUNTRY_RISK_REPORT]: {
    url: `${BASE_URL}Risk/CountryReport`,
  },
  [constants.MICROSITE_COUNTRY_RISK_REPORT]: {
    url: `${BASE_URL}Microsite/Risk/CountryReport`,
  },
  [constants.UNUSED_TICKETS]: {
    url: `${BASE_URL}Search/Profiles/UnusedTickets`,
  },
};

export const getConfig = (type) => {
  return config[type] || {}; // return empty so we don't crash
};
