import {
  Dispatch,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useLayoutEffect,
} from "react";
import { debounce, setPageTitle } from "src/common/helpers";

export function useSetState<T>(initialState: T): [T, Dispatch<Partial<T>>] {
  const [state, setState]: [T, Dispatch<T>] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  );
  return [state, setState];
}

export function useSafeSetState<T>(initialState: T): [T, Dispatch<Partial<T>>] {
  const [state, setState] = useSetState(initialState);
  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  const safeSetState = useCallback(
    (newState: Partial<T>) => mountedRef.current && setState(newState),
    [setState]
  );
  return [state, safeSetState];
}

// Currently built to support changes in width as that is the only responsiveness we need to worry about
// export function onWindowResize(callback, resizeThreshhold = 80) {
//   const curWidth = useRef(window.innerWidth || 1200);

//   // Add a 100ms debounce to reduce rerenders on window resize drag
//   const debouncedCallback = debounce(callback, 100);

//   function handleResize() {
//     const diff = Math.abs(window.innerWidth - curWidth.current);
//     if (diff >= resizeThreshhold) {
//       debouncedCallback();
//     }
//   }

//   useEffect(() => {
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);
// }

export function useOnElementResize({
  callback,
  element,
  resizeThreshhold = 80,
  delay = 100,
  defaultWidth = 400,
}) {
  const curWidth = useRef(
    (element && element.current && element.current.clientWidth) || defaultWidth
  );
  const observer = useRef(null);

  // Add a debounce to reduce rerenders on window resize drag

  const current = element && element.current;

  useEffect(() => {
    // If our browser does not support ResizeObserver, then we get a worse experience and should upgrade
    if (!window.ResizeObserver) return;

    const debouncedCallback = debounce(callback, delay);

    function handleResize(entries) {
      for (const entry of entries) {
        const diff = Math.abs(entry.contentRect.width - curWidth.current);
        if (diff >= resizeThreshhold) {
          curWidth.current = entry.contentRect.width;
          debouncedCallback();
        }
      }
    }

    const curElement = element && element.current;

    // if we are already observing old element, stop
    if (observer && observer.current && current) {
      observer.current.unobserve(current);
    }

    observer.current = new window.ResizeObserver(handleResize);
    if (curElement && observer.current) {
      observer.current.observe(curElement);
    }

    return () => {
      if (observer && observer.current && curElement) {
        observer.current.unobserve(curElement);
      }
    };
  }, [callback, current, delay, element, resizeThreshhold]);
}

export function useFocusRefOnLoad(preventScroll = true) {
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current?.focus({ preventScroll });
  }, []);
  return inputRef;
}

export function useOnMount(callback) {
  const hasRendered = useRef(false);
  useEffect(() => {
    if (!hasRendered.current) {
      callback();
    }
    hasRendered.current = true;
  }, [callback]);
}

export function usePageInit(title: string) {
  useLayoutEffect(() => {
    setPageTitle(title);
  }, []);
}

export const useModalFocusTrap = () => {
  const hasSetInitialFocus = useRef(false);
  const firstFocus = useRef(null);
  const lastFocus = useRef(null);

  useEffect(() => {
    if (firstFocus?.current && !hasSetInitialFocus.current) {
      firstFocus.current.focus();
      hasSetInitialFocus.current = true;
    }
  }, [firstFocus]);

  const firstFocusableElementProps = {
    ref: firstFocus,
    onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === "Tab") {
        if (event.shiftKey) {
          lastFocus?.current?.focus();
          event.preventDefault();
        } else if (!lastFocus.current) {
          event.preventDefault();
        }
      }
    },
  };

  const lastFocusableElementProps = {
    ref: lastFocus,
    onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === "Tab" && !event.shiftKey) {
        firstFocus?.current?.focus();
        event.preventDefault();
      }
    },
  };

  return {
    firstFocusableElementProps,
    lastFocusableElementProps,
  };
};

export const useModalFocusTrapAutomated = (isLoaded: boolean = true) => {
  const hasSetInitialFocus = useRef(false);
  const containerRef = useRef<any>(null);
  useEffect(() => {
    if (isLoaded && !hasSetInitialFocus.current && containerRef.current) {
      hasSetInitialFocus.current = true;
      const { firstFocusable } = getFocusabledElements();
      firstFocusable?.focus();
    }
  }, [isLoaded, containerRef.current]);

  const getFocusabledElements = () => {
    if (!containerRef?.current) return {};
    const focusable = containerRef.current.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const firstFocusable = focusable[0] as HTMLElement;
    const lastFocusable = focusable[focusable.length - 1] as HTMLElement;
    return { firstFocusable, lastFocusable };
  };

  const containerProps = {
    ref: containerRef,
    onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === "Tab") {
        const { firstFocusable, lastFocusable } = getFocusabledElements();

        if (
          !event.shiftKey &&
          event.target === lastFocusable &&
          firstFocusable?.focus
        ) {
          firstFocusable.focus();
          event.preventDefault();
        } else if (
          event.shiftKey &&
          event.target === firstFocusable &&
          lastFocusable?.focus
        ) {
          lastFocusable.focus();
          event.preventDefault();
        }
      }
    },
  };

  return {
    containerProps,
  };
};

export function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
